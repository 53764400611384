/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: 'Outfit', sans-serif;
}

/* Apply the font to the entire app */
body {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  margin: 0;
  background-color: #f1f5f9;
}

#root {
  width: 100%;
  max-width: 375px; /* Max width for mobile-like layout */
  margin: auto;
  background-color: #f1f5f9;
}

/* Additional global styles */
button, input, textarea {
  font-family: 'Outfit', sans-serif;
}
